import * as firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/storage'

let config = {
    apiKey: "AIzaSyBnGbFd17irmeRFI8UzbsCKoSUiEiDuLiM",
    authDomain: "parishram-w3.firebaseapp.com",
    databaseURL: "https://parishram-w3.firebaseio.com",
    projectId: "parishram-w3",
    storageBucket: "parishram-w3.appspot.com",
    messagingSenderId: "783867040185",
    appId: "1:783867040185:web:64ba20e8928ae079142668",
    measurementId: "G-SJ35HGK75C"
};

firebase.initializeApp(config);
const storage = firebase.storage();
const firestore = firebase.firestore();
export {
    storage, firestore as default
}
//export default firebase.firestore();