import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";

export default class AddTutorial extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.saveTutorial = this.saveTutorial.bind(this);
    this.newTutorial = this.newTutorial.bind(this);

    this.state = {
      title: "",
      description: "",
      published: false,

      submitted: false,
    };
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  saveTutorial() {
    let data = {
      title: this.state.title,
      description: this.state.description,
      published: false
    };

    TutorialDataService.create(data)
      .then(() => {
        console.log("Created new item successfully!");
        this.setState({
          submitted: true,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  newTutorial() {
    this.setState({
      title: "",
      description: "",
      published: false,

      submitted: false,
    });
  }

  render() {
    return (
      <div className="submit-form">
        
        {this.state.submitted ? (window.location.href = '/add-selfie') : (
          <div>
             <div className="form-group">
             <textarea className="preFilled form-control" rows="2" cols="41" value="Name: Rajesh Kushwah Govind Singh Kushwah" readOnly />
              
              
              <textarea className="preFilled form-control" rows="3" cols="41" value="Address: WARD NO. 09, MUBARIKPUR NARWAR Madhya Pradesh 473880" readOnly />
   
              
            </div>
            <div className="form-group">
              <label htmlFor="Type of address">Type of Address</label>
              <select className="form-control">
                <option defaultValue value="current">Current</option>
                
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="Type of address">Ownership Status</label>
              <select className="form-control">
                <option defaultValue value="own">Own</option>
                <option value="rented">Rented</option>
                <option value="company_accommodation">Company Accommodation</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="Type of address">Period of Stay</label>
              <select className="form-control">
                <option defaultValue value="0-6 Months">0-6 Months</option>
                <option defaultValue value="0-1 Years">0-1 years</option>
                <option defaultValue value="0-2 Years">0-2 years</option>
                <option defaultValue value="0-3 Years">0-3 years</option>
                
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="Type of address">Relationship with Candidate</label>
              <select className="form-control">
                <option defaultValue value="self">Self</option>
                <option value="father">Father</option>
                <option value="mother">Mother</option>
                <option value="sister">Sister</option>
                <option value="brother">Brother</option>
                <option value="neighbour">Neighbour</option>
                <option value="others">Others</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="title">Verifier Name</label>
              <input
                type="text"
                className="form-control"
                id="title"
                required
                value={this.state.title}
                onChange={this.onChangeTitle}
                name="title"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Verifier Contact Number</label>
              <input
                type="text"
                className="form-control"
                id="description"
                required
                value={this.state.description}
                onChange={this.onChangeDescription}
                name="description"
              />
            </div>

            <button onClick={this.saveTutorial} className="btn btn-primary">
              Submit
            </button>
          </div>
        )}
        <br/>
      </div>
    );
  }
}