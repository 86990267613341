import React, { Component } from "react";
import { Link } from "react-router-dom";
import mainLogo from'./logo.jpg';
import TutorialDataService from "../services/tutorial.service";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.saveTutorial = this.saveTutorial.bind(this);
    this.newTutorial = this.newTutorial.bind(this);

    this.state = {
      title: "",
      description: "",
      published: false,

      submitted: false,
    };
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  saveTutorial() {
    let data = {
      title: this.state.title,
      description: this.state.description,
      published: false
    };

    TutorialDataService.create(data)
      .then(() => {
        console.log("Created new item successfully!");
        this.setState({
          submitted: true,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  newTutorial() {
    this.setState({
      title: "",
      description: "",
      published: false,

      submitted: false,
    });
  }

  render() {
    return (
      <div className="submit-form">
        
        {this.state.submitted ? (
          <div>
            <h5>Thank you for your information.</h5>
            <p>Your id is 508255274, We will get back to you in case of need.</p>
            <button className="btn btn-success" onClick={window.close()}>
              Close
            </button>
          </div>
        ) : (
          <div>
             <div className="form-group">
             <img src={mainLogo} alt="" className="mainLogo" />
              
             
             <label htmlFor="title">Terms and Conditions:
             <textarea className="preFilled terms" rows="9" cols="41" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus metus lacus, non maximus nisi aliquet sed. Praesent vel eros ut ex vestibulum auctor molestie et erat. Mauris vel dolor et sem rutrum convallis sit amet auctor elit. Suspendisse tempor blandit quam, eu maximus ipsum tristique eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus metus lacus, non maximus nisi aliquet sed. Praesent vel eros ut ex vestibulum auctor molestie et erat. Mauris vel dolor et sem rutrum convallis sit amet auctor elit. Suspendisse tempor blandit quam, eu maximus ipsum tristique eget. Donec et nibh quis purus convallis elementum. Sed sodales neque odio, non viverra ipsum volutpat eu. Quisque fringilla nisi odio, faucibus lobortis erat blandit nec." readOnly />
              </label>
           
            </div>

            <Link to="/add-information" className="btn btn-primary">Agree and Continue</Link>
          </div>
        )}
      </div>
    );
  }
}