import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";

export default class AddSelfie extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.saveTutorial = this.saveTutorial.bind(this);
    this.newTutorial = this.newTutorial.bind(this);

    this.state = {
      title: "",
      description: "",
      published: false,

      submitted: false,
    };
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  saveTutorial() {
    let data = {
      title: this.state.title,
      description: this.state.description,
      published: false
    };

    TutorialDataService.create(data)
      .then(() => {
        console.log("Created new item successfully!");
        this.setState({
          submitted: true,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  newTutorial() {
    this.setState({
      title: "",
      description: "",
      published: false,

      submitted: false,
    });
  }

  render() {
    return (
      <div className="submit-form">
        
        {this.state.submitted ? (
          <div>
            <h5>Thank you for your information.</h5>
            <p>Your id is 508255274, We will get back to you in case of need.</p>
            
          </div>
        ) : (
          <div>
          
            <div className="form-group">
              <label htmlFor="title">Selfie</label>
              <input
                type="file"
                className="form-control"
                id="selfie"
                required
                onChange={this.handleChange}
                name="selfie"/>
            </div>

            <div className="form-group">
              <label htmlFor="description">ID Proof</label>
              <input
                type="file"
                className="form-control"
                id="id-proof"
                required
                value={this.state.description}
                onChange={this.onChangeDescription}
                name="id-proof"
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">House Photo</label>
              <input
                type="file"
                className="form-control"
                id="house-photo"
                required
                value={this.state.description}
                onChange={this.onChangeDescription}
                name="house-photo"
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Adhaar - Front</label>
              <input
                type="file"
                className="form-control"
                id="adhaar-front"
                required
                value={this.state.description}
                onChange={this.onChangeDescription}
                name="adhaar-back"
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Adhaar - Back</label>
              <input
                type="file"
                className="form-control"
                id="adhaar-back"
                required
                value={this.state.description}
                onChange={this.onChangeDescription}
                name="adhaar-back"
              />
            </div>
            <button onClick={this.saveTutorial} className="btn btn-primary">
              Submit
            </button>
            
          </div>
        )}
        <br/>
      </div>
    );
  }
}